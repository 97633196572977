<template>
  <div class="vld-parent">
    <loading
      :active.sync="overlayLoader" 
      :can-cancel="false" 
      :is-full-page="true"
    />

    <div v-if="company" class="row">
      <div class="col-12">
        <b-tabs pills card @input="onTabChange">
          <template #tabs-end>
              <!-- <li role="presentation" class="ml-auto nav-item align-self-center">
                  <b-form-group class="mb-0" description="Statut de la vente">
                      <v-select
                          :value="item.status"
                          @input="onStatusChange"
                          :reduce="status => status.value"
                          :options="statusOptions"
                          :clearable="false"
                          required
                      />
                  </b-form-group>
              </li> -->
              <li role="presentation" class="ml-auto nav-item align-self-center">
                <b-button v-if="stripePortalLoading" variant="light-primary" disabled>
                  <b-spinner small /> Paramètres de paiement
                </b-button>
                <b-button v-else variant="light-primary" @click.prevent="generateStripePortalSession">
                  Paramètres de paiement <i class="fas fa-external-link-alt fa-sm ml-3" />
                </b-button>
              </li>
          </template>

          <b-tab active class="p-0 mt-4">
            <template #title>
              <i class="fas fa-info-circle mr-3" />
              Entreprise
            </template>

            <div class="card p-0">
              <form class="form" @submit.prevent="saveCompany">
                <div class="card-body p-9">
                  <div class="row mb-8">
                    <div class="col-xl-3">
                      <div class="fs-6 fw-bold mt-2 mb-3">Raison Sociale</div>
                    </div>
                    <div class="col-xl-9 fv-row">
                      <input
                        type="text"
                        v-model="company.name"
                        class="form-control form-control-solid"
                      />
                    </div>
                  </div>
                  <div class="row mb-8">
                    <div class="col-xl-3">
                      <div class="fs-6 fw-bold mt-2 mb-3">SIRET</div>
                    </div>
                    <div class="col-xl-9 fv-row">
                      <input
                        type="text"
                        v-model="company.siret"
                        class="form-control form-control-solid"
                      />
                    </div>
                  </div>
                  <div class="row mb-8">
                    <div class="col-xl-3">
                      <div class="fs-6 fw-bold mt-2 mb-3">N° de TVA intracommunautaire</div>
                    </div>
                    <div class="col-xl-9 fv-row">
                      <input
                        type="text"
                        v-model="company.vatNumber"
                        class="form-control form-control-solid"
                      />
                    </div>
                  </div>
                  <div class="row mb-8">
                    <div class="col-xl-3">
                      <div class="fs-6 fw-bold mt-2 mb-3">Ville d'immatriculation (RCS)</div>
                    </div>
                    <div class="col-xl-9 fv-row">
                      <input
                        type="text"
                        v-model="company.registrationCity"
                        class="form-control form-control-solid"
                      />
                    </div>
                  </div>
                  <div class="row mb-8">
                    <div class="col-xl-3">
                      <div class="fs-6 fw-bold mt-2 mb-3">Statut juridique</div>
                    </div>
                    <div class="col-xl-9 fv-row">
                      <input
                        type="text"
                        v-model="company.legalStatus"
                        class="form-control form-control-solid"
                      />
                    </div>
                  </div>
                  <div class="row mb-8">
                    <div class="col-xl-3">
                      <div class="fs-6 fw-bold mt-2 mb-3">Capital social (en EUR)</div>
                    </div>
                    <div class="col-xl-9 fv-row">
                      <input
                        type="number"
                        min="0"
                        step="1"
                        v-model="company.capital"
                        class="form-control form-control-solid"
                      />
                    </div>
                  </div>
                  <div class="row mb-8">
                    <div class="col-xl-3">
                      <div class="fs-6 fw-bold mt-2 mb-3">Activité (code NAF/APE)</div>
                    </div>
                    <div class="col-xl-9 fv-row">
                      <input
                        type="text"
                        v-model="company.activityCode"
                        class="form-control form-control-solid"
                      />
                    </div>
                  </div>
                  <div class="row mb-8">
                    <div class="col-xl-3">
                      <div class="fs-6 fw-bold mt-2 mb-3">
                        Informations de contact
                      </div>
                    </div>
                    <div class="col-xl-9 fv-row">
                      <b-row class="mb-4">
                        <b-col>
                          <input
                            type="email"
                            v-model="company.email"
                            placeholder="Email"
                            class="form-control form-control-solid"
                          />
                        </b-col>
                        <b-col>
                          <input
                            type="tel"
                            v-model="company.phone"
                            placeholder="Téléphone"
                            class="form-control form-control-solid"
                          />
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <div class="row mb-8">
                    <div class="col-xl-3">
                      <div class="fs-6 fw-bold mt-2 mb-3">Adresse du siège</div>
                    </div>
                    <div class="col-xl-9 fv-row">
                      <b-row class="mb-4">
                        <b-col>
                          <input
                            type="text"
                            v-model="company.address.line1"
                            placeholder="Ligne 1"
                            class="form-control form-control-solid"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="mb-4">
                        <b-col>
                          <input
                            type="text"
                            v-model="company.address.line2"
                            placeholder="Ligne 2"
                            class="form-control form-control-solid"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="mb-4">
                        <b-col>
                          <input
                            type="text"
                            v-model="company.address.postalCode"
                            placeholder="Code postal"
                            class="form-control form-control-solid"
                          />
                        </b-col>
                        <b-col>
                          <input
                            type="text"
                            v-model="company.address.city"
                            placeholder="Ville"
                            class="form-control form-control-solid"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="mb-4">
                        <b-col>
                          <input
                            type="text"
                            v-model="company.address.state"
                            placeholder="Région"
                            class="form-control form-control-solid"
                          />
                        </b-col>
                        <b-col>
                          <input
                            type="text"
                            v-model="company.address.country"
                            placeholder="Pays"
                            class="form-control form-control-solid"
                          />
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <div class="row mb-8">
                    <div class="col-xl-3">
                      <div class="fs-6 fw-bold mt-2 mb-3">
                        Représentant légal
                      </div>
                    </div>
                    <div class="col-xl-9 fv-row">
                      <b-row class="mb-4">
                        <b-col>
                          <div class="form-group">
                            <b-form-radio-group
                              v-model="company.legalRepresentative.civility"
                              :options="[
                                { text: 'M', value: 'M' },
                                { text: 'Mme', value: 'F' },
                              ]"
                              name="legalRepCivility"
                            />
                          </div>
                        </b-col>
                      </b-row>
                      <b-row class="mb-4">
                        <b-col>
                          <input
                            type="text"
                            v-model="company.legalRepresentative.firstname"
                            placeholder="Prénom"
                            class="form-control form-control-solid"
                          />
                        </b-col>
                        <b-col>
                          <input
                            type="text"
                            v-model="company.legalRepresentative.lastname"
                            placeholder="Nom"
                            class="form-control form-control-solid"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="mb-4">
                        <b-col>
                          <input
                            type="text"
                            v-model="company.legalRepresentative.quality"
                            placeholder="Qualité"
                            class="form-control form-control-solid"
                          />
                        </b-col>
                        <b-col>
                          <input
                            type="email"
                            v-model="company.legalRepresentative.email"
                            placeholder="Email"
                            class="form-control form-control-solid"
                          />
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <div class="row mb-8">
                    <div class="col-xl-3">
                      <div class="fs-6 fw-bold mt-2 mb-3">Conditions Générales de Vente <small>Apparaîtra sur les PDF de Prises en Charges</small></div>
                    </div>
                    <div class="col-xl-9 fv-row">
                      <textarea
                        v-model="company.termsOfSales"
                        class="form-control form-control-solid"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer d-flex justify-content-end py-6 px-9">
                  <button type="submit" class="btn btn-primary">
                    Sauvegarder
                  </button>
                </div>
              </form>
              <!--end:Form-->
            </div>
          </b-tab>

          <b-tab class="p-0 mt-4">
            <template #title>
              <i class="fas fa-folder mr-3" />
              Documents
            </template>

            <div class="card p-0">
              <form @submit.prevent="handleSubmit">
                <div class="card-body p-9">
                  <b-row class="mb-8">
                    <b-col>
                      <h3>
                        <small class="mr-3">
                          <i class="fas fa-check-circle text-success" v-if="company.kbisFilename && company.kbisValidated" />
                          <i class="fas fa-exclamation-circle text-primary" v-else-if="company.kbisFilename" />
                          <i class="fas fa-exclamation-triangle text-danger" v-else />
                        </small>
                        Extrait de Kbis
                        <small class="ml-3">
                          <b-badge v-if="company.kbisFilename && company.kbisValidated" variant="success">Validé</b-badge>
                          <b-badge v-else-if="company.kbisFilename" variant="primary">En cours de validation</b-badge>
                          <b-badge v-else variant="danger">En attente de téléversement</b-badge>
                        </small>
                      </h3>
                      <template v-if="companyFiles.kbis">
                        <embed
                          v-if="companyFiles.kbis.mediatype === 'application/pdf'"
                          :src="`data:application/pdf;base64,${companyFiles.kbis.base64}`"
                          style="width: 100%; min-height: 400px;"
                        >
                        <img
                          v-else
                          :src="`data:${companyFiles.kbis.mediatype};base64,${companyFiles.kbis.base64}`"
                          style="max-height: 400px;"
                        >
                      </template>
                      <div class="form-group">
                          <input type="file" @change="e => uploadFile('kbis', e)">
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mb-8">
                    <b-col>
                      <h3>
                        <small class="mr-3">
                          <i class="fas fa-check-circle text-success" v-if="company.kbisFilename && company.kbisValidated" />
                          <i class="fas fa-exclamation-circle text-primary" v-else-if="company.kbisFilename" />
                          <i class="fas fa-exclamation-triangle text-danger" v-else />
                        </small>
                        Pièce d'identité
                        <small class="ml-3">
                          <b-badge v-if="company.kbisFilename && company.idCardValidated" variant="success">Validée</b-badge>
                          <b-badge v-else-if="company.idCardFilename" variant="primary">En cours de validation</b-badge>
                          <b-badge v-else variant="danger">En attente de téléversement</b-badge>
                          <br>CNI recto/verso, Passeport ou Titre de séjour
                        </small>  
                      </h3>
                      <template v-if="companyFiles.idCard">
                        <embed
                          v-if="companyFiles.idCard.mediatype === 'application/pdf'"
                          :src="`data:application/pdf;base64,${companyFiles.idCard.base64}`"
                          style="width: 100%; min-height: 400px;"
                        >
                        <img
                          v-else
                          :src="`data:${companyFiles.idCard.mediatype};base64,${companyFiles.idCard.base64}`"
                          style="max-height: 400px;"
                        >
                      </template>
                      <div class="form-group">
                          <input type="file" @change="e => uploadFile('idCard', e)">
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mb-8">
                    <b-col>
                      <h3>
                        <small class="mr-3">
                          <i class="fas fa-check-circle text-success" v-if="company.kbisFilename && company.kbisValidated" />
                          <i class="fas fa-exclamation-circle text-primary" v-else-if="company.kbisFilename" />
                          <i class="fas fa-exclamation-triangle text-danger" v-else />
                        </small>
                        Conditions Générales de Service
                        <small class="ml-3">
                          <b-badge v-if="company.cgsFilename" variant="success">Validées</b-badge>
                          <b-badge v-else variant="danger">En attente de signature</b-badge>
                        </small>
                      </h3>
                      <div v-if="companyFiles.cgs && companyFiles.cgs.base64">
                        <a href="#" @click.prevent="downloadPdf(companyFiles.cgs.base64)">Télécharger</a>
                        <embed
                          :src="`data:application/pdf;base64,${companyFiles.cgs.base64}`"
                          style="width: 100%; min-height: 400px;"
                        >
                      </div>
                      <a v-else href="#" @click.prevent="reSendCgsValidationLink()">Renvoyer le lien de signature des CGS <i class="fas fa-sm fa-paper-plane ml-1 text-primary"></i></a>
                    </b-col>
                  </b-row>
                </div>
                <div class="card-footer d-flex justify-content-end py-6 px-9">
                  <button type="submit" class="btn btn-primary">
                    Téléverser <i class="fas fa-sm fa-upload ml-3"></i>
                  </button>
                </div>
              </form>
              <!--end:Form-->
            </div>
          </b-tab>

          <!-- <b-tab>
            <template v-slot:title>
              <i class="fas fa-boxes mr-3" />
              Boutique
            </template>
          </b-tab> -->
        </b-tabs>
      </div>
    </div>

    <b-modal
      v-model="modal.show"
      :title="modal.title"
      hide-footer
      no-close-on-backdrop
    >
      <div class="d-block"></div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { REFRESH_USER } from "@/core/services/store/auth.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "company",
  components: {
    Loading,
  },
  data() {
    return {
      company: null,
      shope: null,
      modal: {
        show: false,
        title: "...",
      },
      stripePortalLoading: false,
      companyFiles: {},
      uploadFiles: [],
      overlayLoader: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Entreprise" }]);
    this.$store.dispatch(REFRESH_USER);
    this.fetchCompany();

    if (this.$route.query['accept-terms-token']) {
      this.acceptTerms(this.$route.query['accept-terms-token']);
    }
  },
  methods: {
    /**
     * Detect tab change event to fetch documents when needed
     */
    onTabChange(idx) {
      if (idx === 1) {
        this.$nextTick(() => {
          this.fetchCompanyFiles();
        });
      }
    },
    downloadPdf(base64) {
      const linkSource = `data:application/pdf;base64,${base64}`;
      const downloadLink = document.createElement("a");
      const fileName = "refectio-conditions-generales-de-service.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    uploadFile (filename, event) {
      const newFileObj = {
        filename,
        fileObject: event.target.files[0]
      };
      
      const file = this.uploadFiles.find(e => e.filename == filename);
      if (file) {
        file.filename = newFileObj.filename;
        file.fileObject = newFileObj.fileObject;
      } else {
        this.uploadFiles.push(newFileObj);
      }
    },
    handleSubmit() {
      const formData = new FormData();
      for (const file of this.uploadFiles) {
        formData.append(file.filename, file.fileObject)
      }

      ApiService.post(`/companies/${this.company.id}/files`, formData, {})
        .then(() => {
          this.fetchCompany();
          this.fetchCompanyFiles();
          this.$store.dispatch(REFRESH_USER);
          this.$swal('Vos documents ont bien été mis à jour. Ils seront validés par notre équipe dans les plus brefs délais.', '', 'success');
        });
    },
    reSendCgsValidationLink() {
      ApiService.post('/auth/send-verification-email')
        .then(() => {
          this.$swal('Un nouveau lien vous a été envoyé par mail ! (Pensez aussi à vérifier dans vos courriers indésirables)', '', 'success');
        });
    },
    fetchCompany() {
      ApiService.get("/companies", this.currentUser.company.id).then(({ data }) => {
        this.company = data;
      });
    },
    fetchCompanyFiles() {
      ApiService.get("/companies", `${this.currentUser.company.id}/files`).then(({ data }) => {
        this.companyFiles = data;
      });
    },
    saveCompany() {
      const shop = {
        name: this.company.name,
        address: this.company.address,
        legalRepresentative: this.company.legalRepresentative,
      };

      Promise.all([
        ApiService.update('/companies', this.company.id, this.company),
        ApiService.update('/shops', this.currentUser.shops[0], shop),
      ]).then(() => {
        this.$store.dispatch(REFRESH_USER);
        this.$swal('Entreprise mise à jour', '', 'success');
      });
    },
    popupCenter({url, title, w, h}) {
      // Fixes dual-screen position                             Most browsers      Firefox
      const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
      const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

      const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
      const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

      const systemZoom = width / window.screen.availWidth;
      const left = (width - w) / 2 / systemZoom + dualScreenLeft
      const top = (height - h) / 2 / systemZoom + dualScreenTop
      const newWindow = window.open(url, title, 
        `
        directories=0,
        titlebar=0,
        toolbar=0,
        location=0,
        status=0,
        menubar=0,
        scrollbars=yes,
        width=${w / systemZoom}, 
        height=${h / systemZoom}, 
        top=${top}, 
        left=${left}
        `
      )

      if (window.focus) newWindow.focus();
    },
    generateStripePortalSession() {
      this.stripePortalLoading = true;
      ApiService.post('/stripe/portal-session')
        .then(({ data }) => {
          window.location.href = data.url;
        })
        .catch(() => {
          this.stripePortalLoading = false;
        });
    },
    acceptTerms(token) {
      this.overlayLoader = true;
      ApiService.post(`/auth/accept-terms?token=${token}`)
        .then(() => {
          this.overlayLoader = false;
          this.fetchCompany();
          this.fetchCompanyFiles();
          this.$store.dispatch(REFRESH_USER);
          this.$swal('Les CGS ont bien été signées et ajoutées à vos documents.', '', 'success');
        })
        .catch(() => {
          this.overlayLoader = false;
        });
    },
  },
};
</script>
